import React from "react"
import styled from 'styled-components';

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </Layout>
)

export default NotFoundPage

const Container = styled.div`
    background: linear-gradient(to bottom, #71c4de 10%,#4C8495 50%,#5899AD 70%, #39626F 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 50vh;
    padding: 0 1rem;
    position: relative;
`
